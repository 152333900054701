<template>
  <div>
    <div class="teacher">
      <section class="info">
        <div class="info-content">
          <img :src="thumbs" alt="" />
          <div class="description">
            <h2>{{ name }}</h2>
            <p class="company">{{ company }} {{ position }}</p>
            <p v-html="desc"></p>
          </div>
        </div>
      </section>
      <section class="course-content">
        <div class="no-course" v-if="thisCourse.length <= 0">
          <div class="left"></div>
          <div class="right">
            <h3>即将开始的GIAC课程</h3>
            <p>
              本届GIAC暂无分享议题，嘉宾将对大会整体议题甄选和议题质量进行把控和指导。
            </p>
          </div>
        </div>
        <div
          class="course"
          v-else-if="thisCourse.length > 0"
          v-for="(item, index) in thisCourse"
          :key="index"
        >
          <div class="left">
            <div class="text">
              by <span>{{ item.venueName }}</span>
              <div class="time">
                {{ formatTime(item.date) }} {{ item.startTime }}--{{
                  item.endTime
                }}
              </div>
            </div>
          </div>
          <div class="right">
            <h3>{{ item.title }}</h3>
            <div class="text mob">
              by <span>{{ item.venueName }}</span>
              <div class="time">
                {{ formatTime(item.date) }} {{ item.startTime }}--{{
                  item.endTime
                }}
              </div>
            </div>
            <h5>课程概要</h5>
            <p v-html="item.desc"></p>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import {sid, getLecturerInfo} from "@/utils/api.js";

export default {
  data() {
    return {
      name: "",
      company: "",
      desc: "",
      position: "",
      publicCourse: [],
      thisCourse: [],
      thumbs: "",
    };
  },
  mounted() {
    this.getTeacher(this.$route.query.id);
  },
  methods: {
    formatTime(timestamp) {
      const time = new Date(timestamp * 1000);
      const year = time.getFullYear();
      let month = time.getMonth() + 1;
      let day = time.getDate();
      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }
      return `${year}-${month}-${day}`;
    },
    getTeacher(id) {
      this.$http
        .jsonp(`${getLecturerInfo}/${sid(this.global.year)}/${id}`)
        .then((data) => {
          return data.json();
        })
        .then((res) => {
          // console.log(res);
          if (res.errno == 0) {
            for (let key in res.data) {
              this[key] = res.data[key];
            }
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.teacher {
  .info {
    background: #f4f4f4;
    padding-top: 43px;
    padding-bottom: 51px;
    .info-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      max-width: 1107px;
      margin-left: auto;
      margin-right: auto;
      img {
        width: 193px;
        height: 211px;
        border-radius: 7px;
      }
      .description {
        text-align: left;
        padding-top: 21px;
        padding-bottom: 10px;
        h2 {
          margin: 0;
          font-size: 36px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #212121;
        }
        p {
          //   max-width: 853px;
          width: 853px;
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #999999;
          line-height: 22px;
        }
        p.company {
          margin-top: 19px;
          margin-bottom: 32px;
        }
      }
    }
  }
  .course-content {
    width: 100%;
    background: #f1f4f7;
    padding-top: 96px;
    padding-bottom: 104px;
    .no-course,
    .course {
      max-width: 1142px;
      margin-left: auto;
      margin-right: auto;
      padding-top: 56px;
      padding-bottom: 56px;
      background: #ffffff;
      border-left: 8px solid #003177;
      display: flex;
      flex-direction: row;
      .left {
        width: 30%;
        display: flex;
        align-items: center;
        justify-content: center;
        .text {
          text-align: left;
          font-size: 18px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #131313;
          span {
            font-size: 18px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #1f64af;
          }
          .time {
            margin-top: 12px;
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #999999;
          }
        }
      }
      .right {
        width: 70%;
        text-align: left;
        h3 {
          margin-bottom: 20px;
          font-size: 30px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #1f64af;
        }
        p {
          max-width: 738px;
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #999999;
        }
      }
    }
    .course {
      // .left {
      // }
      .right {
        .text.mob {
          display: none;
        }
        h3 {
          margin-bottom: 47px;
        }
        h5 {
          margin-bottom: 30px;
          font-size: 18px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #131313;
        }
        // p {
        // }
      }
    }
  }
}
@media (max-width: 1023px) {
  .teacher {
    margin-top: 0;
    background: #f1f4f7;
    .info {
      padding-top: 40px;
      padding-bottom: 25px;
      .info-content {
        flex-direction: column;
        width: 85vw;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        img {
          max-width: 35vw;
          height: auto;
          border-radius: 7px;
          margin-left: auto;
          margin-right: auto;
        }
        .description {
          padding-top: 37px;
          text-align: center;
          h2 {
            font-size: 15px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #010101;
            line-height: 25px;
          }
          p {
            width: 100%;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #4c4c4c;
            line-height: 24px;
            text-align: left;
          }
          p.company {
            margin-top: 0;
            margin-bottom: 0;
            padding-top: 14px;
            padding-bottom: 28px;
            font-size: 13px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #293f76;
            line-height: 15px;
            text-align: center;
          }
        }
      }
    }
    .course-content {
      width: 85vw;
      margin-left: auto;
      margin-right: auto;
      padding-top: 25px;
      .no-course {
        padding-top: 0;
        padding-bottom: 0;
        flex-direction: column;
        .left {
          display: none;
        }
        .right {
          width: 100%;
          padding: 21px 4.5vw 24px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #2466ad;
          .text.mob {
            display: block;
            text-align: left;
            font-size: 18px;
            padding-bottom: 35px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #131313;
            span {
              font-size: 13px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #1f64af;
            }
            .time {
              margin-top: 14px;
              font-size: 13px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #999999;
              line-height: 24px;
            }
          }
          h3 {
            margin-bottom: 14px;
            font-size: 15px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #2466ad;
          }
          h5 {
            font-size: 15px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #131313;
            margin-bottom: 28px;
          }
          p {
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 18px;
          }
        }
      }
      .course {
        padding-top: 0;
        padding-bottom: 0;
        flex-direction: column;
        border-left: 4px solid #003177;

        .left {
          display: none;
        }
        .right {
          width: 100%;
          padding: 40px 4.5vw 59px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #2466ad;
          .text.mob {
            display: block;
            text-align: left;
            font-size: 13px;
            padding-bottom: 35px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #131313;
            span {
              font-size: 13px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #1f64af;
            }
            .time {
              margin-top: 14px;
              font-size: 13px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #999999;
            }
          }
          h3 {
            margin-bottom: 18px;
            font-size: 18px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #2466ad;
            line-height: 27px;
          }
          h5 {
            font-size: 15px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #131313;
            margin-bottom: 28px;
          }
          p {
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 18px;
          }
        }
      }
    }
  }
}
</style>